import React, { useEffect } from 'react';
import './slider.scss';
import { AiOutlineArrowLeft, AiOutlineArrowRight } from 'react-icons/ai';
import { sliderData } from './sliderdata';

import 'bootstrap/dist/css/bootstrap.min.css';


const Slider = ({ currentSlide, nextSlide, prevSlide, order }) => {
  const slideLength = sliderData.length;

  return (
    <div className='slider'>
      {sliderData.map((slide, index) => {
        const { image, heading, desc, video } = slide;
        return (
          <div key={index} className={index === currentSlide ? 'slide current' : 'slide'}>
            {index === currentSlide && (
              <>
                <img src={image} alt='slide' />
                <div className='content'>
                  <h2>{heading}</h2>
                  <p>{desc}</p>
                  {video && (
                    <a href={video} target="_blank" rel="noopener noreferrer">Watch Video on YouTube</a>
                  )}
                   
                    
                  <hr />
                  
                </div>
              </>
            )}
          </div>
        );
      })}
       <button className="arrow prev" onClick={prevSlide}><AiOutlineArrowLeft /></button>
      <button className="arrow next" onClick={nextSlide}><AiOutlineArrowRight /></button>
    </div>
  );
};

export default Slider;