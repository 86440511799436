import React from 'react';




export const sliderData = [
  
    {
      image: "https://cdn.openart.ai/uploads/image_2s4Jhq-s_1712089238003_512.webp",
      heading: "The Ones Who Walk Away from Omelas",
      desc: "The ones who walk away from Omelas is a short story by Ursular K. Le Guin that set place in the idyllic city of Omelas, a seemingly perfect utopia by the sea, the people of Omelas are described as happy. But at what cost? And the question that comes in from this story is 'Logic or Morals'? "
      
    },
    {
      image: "https://cdn.openart.ai/uploads/image_PrglHa3r_1712089095693_512.webp",
      heading: "Who is Ursula K. Le Guin ?",
      desc:  " Ursula K. Le Guin was an American author born October 21, 1929 in Berkeley, California As a child, she immersed herself in fantasy tales, mythology, and folklore, laying the foundation for her future literary endeavors. She is known for her popular works : A Wizard of Earthsea (1968), The Left Hand of Darkness (1969), The Hainish Cycle, The Ones Who Walk Away from Omelas (1973). Ursula K. Le Guin’s influence extends beyond genre boundaries. Her exploration of social, political, and existential themes resonates with readers of all backgrounds. She subverted stereotypes, championed diversity, and emphasized the power of imagination. Le Guin’s legacy endures, reminding us that great literature transcends time and space. ",
    },
    {
      image: "https://cdn.openart.ai/uploads/image_3yPtC_tp_1712088948027_raw.jpg",
      heading: "Why Did they Walk Away ?",
      desc: "The cost of happiness of the citizens of Omelas is the suffering of a single child, as describe in the story the child was locked in a basement/room under the city with very poor living conditions, the room was described as dirty, damp and small almost like a prison and in it was a child who's age was not specific. Some of the citizens of Omelas decided to walk away from th city leaving their happy life behind upon the discovery of this, but is this the right choice ?. What would you do, will it sit well with you knowing someone is out there suffering in order to make you happy? or will you walk away like the people of Omelas or will you consider the option of taking actions against this sort of society? ",
    },
    {
        image: "https://cdn.openart.ai/uploads/image_wEye3Oni_1712089410143_512.webp",
        heading: "",
        desc:  "The concept of utilitarianism is something that is deeply embedded in human history. Everyone knows of the period of slavery. It is essentially putting a group of people in discomfort for the comfort of another group.",
        video: "https://www.youtube.com/embed/BRa0EK-K8OE?si=bYMsI4rMvOqKU74Z"
      },
      {
        image: "https://cdn.openart.ai/uploads/image_cdWnoBiT_1712089517329_512.webp",
        heading: "",
        desc:  "There have also been times in human history wheere human sacrifices were made in order to appease some gods for the prosperity of a city or kingdom.",
        video: "https://www.youtube.com/embed/y7Kxum63CtA?si=jRoyVXsmfF0krJp2"
      },
    
    {
      image: "https://cdn.openart.ai/uploads/image_NjcfMToy_1712089650454_512.webp",
      heading: "",
      desc:  "This concept of utilitarianism is still evident in the world of today, sacrificing some peoples joy for the happiness of many, but a lot of people just ignorant about it. In todays materialistic world everyone wants to use the latest cell phone, drive the latest cars, wear the best clothes but no one just cares about how it was made, the living conditions or the health of those who produce it. Shein a fashion company in China that is popular and have lots of demands due the cheap products and services they offer has recently been found out to be exploiting its workers by making them work under terrible conditions for long shifts and also underpaying them, despite the knowledge of this information that we now have, a lot of people still use shein. ",
      
    },
    
    
    {
      image: "https://cdn.openart.ai/uploads/image_iGfauqJn_1712089785752_512.webp",
      heading: "Why Did the People of Omelas Walk Away",
      desc:  "Was it guilt that made the people of Omelas walk away after knowing about the young child suffering? Actually we do not know, 'One thing I know there is none of in Omelas is guilt'(Le Guin). From the story it feels like the people of Omelas felt guilt which is why they left.",
    },
    {
      image: "https://cdn.openart.ai/uploads/image_S7qfCo6L_1712089965531_512.webp",
      heading: "Personal Connection",
      desc:  "My personal connection to this is solely because I am someone that loves to compare logic and morals, so I ask myself 'Is it acceptable to utilize the suffering of one person to benefit the lives of thousands of other people?' Question to you 'Would you also walk away from Omelas or you will stay ?'",
    },
    {
        image: "https://cdn.openart.ai/uploads/image_DleAQj2v_1712090886977_512.webp",
        heading: "THE END",
        desc:  "",
      },
    
   
  ];
