import React, { useState, useEffect } from 'react';

import './home.css';
import Slider from './slider/slider';
import { sliderData } from './slider/sliderdata';
// import Image1 from './images/Omelas 1.jpeg';
// import Image2 from './images/Omelas 10.jpg';
// import Image3 from './images/Omelas 9.jpg';
// import Image4 from './images/Omelas 8.jpg';
// import Image5 from './images/Omelas 7.jpg';
// import Image6 from './images/Omelas 6.jpg';
// <link rel="stylesheet" href="https://fonts.googleapis.com/css2?family=Roboto:wght@400;700&display=swap"></link>


const Home = () => {
//   const slides = [
//     {
//       image: Image1,
//       text: <h1 style={{ fontFamily: 'Roboto, sans-serif' }}>The Ones Who Walk Away From Omelas</h1>
//       ,
//     },
//     {
//       image: Image2,
//       text: 'Hello my name is Abdulrahman Sanni',
//     },
//     {
//       image: Image3,
//       text: 'Hello my name is Abdulrahman Sanni',
//     },
//     {
//       image: Image4,
//       text: 'Hello my name is Abdulrahman Sanni',
//     },
//     {
//       image: Image5,
//       text: 'Hello my name is Abdulrahman Sanni',
//     },
//     {
//       image: Image6,
//       text: 'Hello my name is Abdulrahman Sanni',
//     },
//     // Add more slides as needed
//   ];


    const [currentSlide, setCurrentSlide] = useState(0);
  
    useEffect(() => {
      const recognition = new window.webkitSpeechRecognition();
      recognition.continuous = true;
      recognition.lang = 'en-US';
  
      recognition.onresult = (event) => {
        const transcript = event.results[event.results.length - 1][0].transcript.toLowerCase();
        if (transcript.includes('next slide')) {
          nextSlide();
        } else if (transcript.includes('previous slide')) {
          prevSlide();
        }
      };
  
      recognition.start();
  
      return () => {
        recognition.stop();
      };
    }, []);
  
    const nextSlide = () => {
      setCurrentSlide((prevSlide) => (prevSlide === sliderData.length - 1 ? 0 : prevSlide + 1));
    };
  
    const prevSlide = () => {
      setCurrentSlide((prevSlide) => (prevSlide === 0 ? sliderData.length - 1 : prevSlide - 1));
    };
  
    
  
    return (
      <div>
        <div className="slider-container">
          <Slider currentSlide={currentSlide} nextSlide={nextSlide} prevSlide={prevSlide}  />
        </div>
      </div>
    );
  };
  
  export default Home;